import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider, useQuery } from '@apollo/client'

import { FeaturesWrapper, useSplitEnabled } from '@buffer-mono/features'
import { ReduxWrappedNavigator } from '@buffer-mono/app-shell'
import { PopcornProvider } from '@buffer-mono/popcorn'

import { store, history } from '~publish/legacy/store'
import { PusherContextProvider } from '~publish/services/pusher'
import { theme } from '~publish/legacy/theme'
import GlobalStyles from '~publish/legacy/global-styles/globalStyles'
import '~publish/legacy/composer/composer/components/css/constants.css'
import { client } from '~publish/legacy/apollo-client'
import { AccountContext, GET_ACCOUNT } from '~publish/legacy/accountContext'
import { env } from '~publish/env'

import getErrorBoundary from '../../components/ErrorBoundary'
import App from '../App'

import { MixpanelSessionReplay } from '../MixpanelSessionReplay/MixpanelSessionReplay'

import '../../components/i18n'
function getQueryParams(): URLSearchParams {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  return params
}

const ErrorBoundary = getErrorBoundary()

const Publish = (): JSX.Element => {
  const {
    loading,
    data: account,
    refetch: refetchAccount,
  } = useQuery(GET_ACCOUNT)

  const { ORGANIZATION_EVENT_KEY } = window?.appshell?.eventKeys || {}
  const { billingUpdated } = window?.appshell?.actionKeys || {}

  useEffect(() => {
    store.dispatch({ type: 'APP_INIT', queryParams: getQueryParams() })
  }, [])

  useEffect(() => {
    function onOrgChange({ detail }: { detail: { action: string } }): void {
      if (!detail.action) {
        refetchAccount()
      } else if (detail.action === billingUpdated) {
        refetchAccount()
        store.dispatch({ type: 'INIT_ORGANIZATIONS' })
      }
    }
    window.addEventListener(ORGANIZATION_EVENT_KEY, onOrgChange)

    return function cleanup(): void {
      window.removeEventListener(ORGANIZATION_EVENT_KEY, onOrgChange)
    }
  }, [refetchAccount, ORGANIZATION_EVENT_KEY, billingUpdated])

  return (
    <ErrorBoundary>
      <FeaturesWrapper environment={env.VITE_SPLIT_ENV}>
        <MixpanelSessionReplay />
        <AccountContext.Provider
          value={{ ...account, loading, refetchAccount }}
        >
          <Provider store={store}>
            <Router history={history}>
              <CompatRouter>
                <PusherContextProvider>
                  <PopcornProviderWrapper>
                    <ThemeProvider theme={theme}>
                      <ReduxWrappedNavigator
                        hideNavigation={false}
                        hideBanners={false}
                      />
                      <App />
                    </ThemeProvider>
                  </PopcornProviderWrapper>
                </PusherContextProvider>
              </CompatRouter>
            </Router>
          </Provider>
        </AccountContext.Provider>
      </FeaturesWrapper>
    </ErrorBoundary>
  )
}

export function PublishRoot(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <GlobalStyles />
      <Publish />
    </ApolloProvider>
  )
}

// TODO: This wrapper is necessary to have split.io properly setup to enable, the
// theme switcher, remove once the theme switcher is disabled or becomes GA
function PopcornProviderWrapper({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { isEnabled: isThemeSwitcherEnabled = false } = useSplitEnabled(
    'buffer-test-theme-switcher',
  )
  return (
    <PopcornProvider
      themeSwitcherEnabled={isThemeSwitcherEnabled}
      theme="light"
      portalContainerId="portals-container"
    >
      {children}
    </PopcornProvider>
  )
}
